<template>
  <div class="box">
    <div class="header">
      <div class="header-search">
        <van-nav-bar
          :title="title"
          left-text=""
          left-arrow
          @click-left="onClickLeft"
        >
        </van-nav-bar>
      </div>
    </div>
    <div class="content contentbox loadingbox" v-if="loadingfalg">
      <van-loading size="24px" vertical>加载中...</van-loading>
    </div>
    <div class="content contentbox" v-if="showage && !loadingfalg">
      <BasicTop :basicTop="basicTop" numberrows="2" />
      <Steps
        :levels="levels"
        v-if="levels && appStatus == 1"
        :hierarchy="hierarchy"
      />
      <div class="content-show">
        <van-tabs v-model="active" :animated="true">
          <van-tab title="详情页" name="a">
            <van-list :finished="true" finished-text="没有更多了">
              <van-collapse v-model="activeNames">
                <van-collapse-item title="标段包信息" name="1" icon="label">
                  <van-cell-group class="group">
                    <van-cell title="招标编号" :value="details.fileCode" />
                    <van-cell
                      title="招标方式"
                      :value="details.tendermodeName"
                    />
                    <van-cell
                      title="标段(包)名称"
                      :value="details.packageName"
                    />
                    <van-cell
                      title="资审方式"
                      :value="
                        details.checkType == 1
                          ? '资格预审'
                          : details.checkType == 2
                          ? '资格后审'
                          : details.checkType == 3
                          ? '集中资格预审'
                          : ''
                      "
                    />
                    <van-cell title="阶段" :value="details.status" />
                  </van-cell-group>
                </van-collapse-item>
                <van-collapse-item title="评标与考察结果" name="2" icon="label">
                  <van-cell-group class="group">
                    <van-cell title="开标时间" :value="details.openTime" />
                    <van-cell title="开标地点" :value="details.openAddr">
                    </van-cell>
                    <van-cell title="招标类型" :value="details.frmtypeName" />
                    <van-cell title="定标意见" :value="details.remark" />
                    <van-cell title="评标报告附件">
                      <div
                        v-for="(item, index) in details.bidAttachmentVosr"
                        :key="index"
                        class="cell"
                      >
                        <a
                          v-if="item.suffix != 'rar' && item.suffix != 'zip'"
                          :href="
                            '/api-ec/fileDownload/FileuploadAction/' +
                              item.id +
                              '-' +
                              item.title
                          "
                          >{{ item.title }}</a
                        >
                        <span v-else @click="onCompression">{{
                          item.title
                        }}</span>
                      </div>
                    </van-cell>
                    <van-cell title="附件" value="内容">
                      <div
                        v-for="(item, index) in details.bidAttachmentVos"
                        :key="index"
                        class="cell"
                      >
                        <a
                          v-if="item.suffix != 'rar' && item.suffix != 'zip'"
                          :href="
                            '/api-ec/fileDownload/FileuploadAction/' +
                              item.id +
                              '-' +
                              item.title
                          "
                          >{{ item.title }}</a
                        >
                        <span v-else @click="onCompression">{{
                          item.title
                        }}</span>
                      </div>
                    </van-cell>
                  </van-cell-group>
                </van-collapse-item>
              </van-collapse>
            </van-list>
          </van-tab>
          <van-tab title="投标人" name="b"
            ><van-list
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="onLoad"
              :immediate-check="false"
            >
              <div
                class="supplies"
                v-for="(item, index) in bidder"
                :key="index"
              >
                <div class="supplies-title">{{ item.suppName }}</div>
                <div class="supplies-text">
                  评标价（万元）: {{ item.evaluePrices }}
                </div>
                <div class="supplies-text">
                  商务分: {{ item.businessScore }}
                </div>
                <div class="supplies-text">技术分: {{ item.techScore }}</div>
                <div class="supplies-text">总分: {{ item.finallyScore }}</div>
                <div class="supplies-text">排序: {{ item.orderNo }}</div>
                <div class="supplies-text">现场复核: {{ item.sitereView }}</div>
                <div class="supplies-text">
                  投标人类型: {{ item.supplierTypeName }}
                </div>
                <div class="supplies-text">是否中标: {{ item.flag }}</div>
              </div>
            </van-list></van-tab
          >
          <van-tab title="审批记录" name="c" v-if="appStatus != 1">
            <van-list :finished="true" finished-text="没有更多了">
              <div
                class="record"
                v-for="(item, index) in recordList"
                :key="index"
              >
                <van-cell-group>
                  <van-cell title="审批层级" :value="item.node"></van-cell>
                  <van-cell title="审批节点" :value="item.nodename"></van-cell>
                  <van-cell title="审批人" :value="item.userName"></van-cell>
                  <van-cell
                    title="审批时间"
                    :value="item.endDateTimeStr"
                  ></van-cell>
                  <van-cell
                    title="审批结论"
                    :value="item.resultType"
                  ></van-cell>
                  <van-cell
                    title="审批意见"
                    :value="item.result"
                    v-if="item.resultType == '不同意'"
                  ></van-cell>
                </van-cell-group>
              </div>
            </van-list>
          </van-tab>
        </van-tabs>
      </div>
    </div>
    <div
      class="footer"
      v-if="appStatus == 1 && showage == true && !loadingfalg"
    >
      <div class="footer-left" @click="agree" v-if="show">
        <span><van-icon name="success" size="20"/></span>
        <span>同意</span>
      </div>
      <div class="footer-right" @click="noagree" v-if="show">
        <span><van-icon name="cross" size="20"/></span>
        <span>不同意</span>
      </div>
      <div class="footer-left" v-if="!show">
        <span><van-icon name="success" size="20"/></span>
        <span>提交中</span>
      </div>
      <div class="footer-right" v-if="!show">
        <span><van-icon name="cross" size="20"/></span>
        <span>不同意</span>
      </div>
    </div>
    <Noagree v-if="!showage" :submitflag="submitflag" />
  </div>
</template>
<script>
import { Dialog } from "vant";
import { Toast } from "vant";
export default {
  data() {
    return {
      loadingfalg: true,
      submitflag: false,
      show: true,
      hierarchy: "",
      pageIndex: 1,
      pageSize: 10,
      sort: "asc",
      sortType: 1,
      statusType: 1,
      showage: true,
      bidder: [],
      title: "定标结果审批详情",
      loading: false,
      finished: true,
      isLoading: false,
      active: "a",
      recordList: [],
      activeNames: ["1", "2", "3", "4", "5", "6"],
      details: [],
      levels: null,
      basicTop: {
        data: [],
        columns: [
          {
            prop: "goods",
            label: "招标物资",
          },
          {
            prop: "money",
            label: "概算金额(万元)",
          },
          {
            prop: "openTime",
            label: "开标时间",
          },
          {
            prop: "tendermodeName",
            label: "招标方式",
          },
        ],
      },
    };
  },
  created() {
    this.toPackageInfo();
    this.toPackageSupps();
    if (this.appStatus != 1) {
      this.toApprovesPackInfo();
    }
  },
  computed: {
    packId() {
      return this.$route.query.id;
    },
    appStatus() {
      return this.$route.query.appStatus;
    },
  },
  methods: {
    async toPackageInfo() {
      const params = {
        packId: this.packId,
      };
      let data = await this.$api.ec.toPackageInfo(params);
      if (data) {
        this.loadingfalg = false;
      }
      this.details = data;
      if (this.details.bidAttachmentVosr) {
        this.details.bidAttachmentVosr.map((item) => {
          item.suffix = item.title.substring(item.title.lastIndexOf(".") + 1);
        });
      }
      if (this.details.bidAttachmentVos) {
        this.details.bidAttachmentVos.map((item) => {
          item.suffix = item.title.substring(item.title.lastIndexOf(".") + 1);
        });
      }
      this.basicTop.data = data;
      this.levels = Number(data.levels);
      this.hierarchy = Number(data.wfCounts);
    },
    onCompression() {
      Dialog.alert({
        message: "压缩包请到电脑端查看",
      }).then(() => {
        // on close
      });
    },
    async toPackageSupps() {
      const params = {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        statusType: this.statusType,
        sort: this.sort,
        sortType: this.sortType,
        packId: this.packId,
      };
      let data = await this.$api.ec.toPackageSupps(params);
      if (data) {
        if (data.data) {
          if (data.data.length != 0) {
            this.bidder = [...this.bidder, ...data.data];
          }
          if (data.data.length != 10) {
            this.loading = true;
            this.finished = true;
          } else {
            this.loading = false;
            this.finished = false;
          }
        }
        if (!data.data) {
          this.loading = true;
          this.finished = true;
        }
      }
    },
    ondown(item) {
      let query = {
        id: item.id,
        type: 2,
      };
      this.$router.push({ name: "preview", query });
    },
    async toApprovesPackInfo() {
      const params = {
        packId: this.packId,
        pageIndex: 1,
        pageSize: 100,
      };
      let data = await this.$api.ec.toApprovesPackInfo(params);
      this.recordList = data.data;
    },
    async auditApply(o, message) {
      let params;
      if (o == 2) {
        this.submitflag = true;
        params = {
          id: this.packId,
          result: 0,
          approvalOpinion: message,
        };
      } else {
        params = {
          id: this.packId,
          result: 1,
          approvalOpinion: "同意",
        };
      }
      let data = await this.$api.ec.doPackApp(params);
      if (data) {
        Toast.success("提交成功");
        setTimeout(() => {
          this.$router.push({
            name: "eccalibration",
            query: { type: 1 },
          });
        }, 1000);
      }
    },
    onClickLeft() {
      if (this.showage) {
        this.$router.push({
          name: "eccalibration",
        });
      } else {
        this.showage = true;
        this.title = "定标结果审批详情";
      }
    },
    onLoad() {
      this.pageIndex++;
      this.toPackageSupps();
    },
    listDetails() {},
    agree() {
      Dialog.confirm({
        title: "审批",
        message: "是否同意",
      })
        .then(() => {
          this.show = false;
          this.auditApply();
        })
        .catch(() => {
          // on cancel
        });
    },
    noagree() {
      this.title = "审批";
      this.showage = false;
    },
  },
};
</script>
<style lang="less" scoped>
.footer-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #70e6ea;
}
.footer-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #f77269;
}
/deep/.group {
  .van-cell {
    color: #8f8f8f;
  }
}
/deep/.van-cell {
  background-color: transparent;
}
/deep/.van-cell {
  font-size: 12px;
}
/deep/.van-collapse-item__content {
  font-size: 12px;
  padding: 0;
}
/deep/.van-cell__value {
  color: #333;
}
.record {
  margin-top: 10px;
}
</style>
